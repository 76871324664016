var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-container",
    [
      _c(
        "el-row",
        [
          _c("el-col", [
            _c(
              "div",
              [
                _c("el-card", { attrs: { shadow: "never" } }, [
                  _c(
                    "div",
                    { attrs: { id: "printBody" } },
                    [
                      _c(
                        "el-container",
                        [
                          _c(
                            "div",
                            { staticClass: "left-content" },
                            [
                              _c("head-layout", {
                                attrs: {
                                  "head-title":
                                    _vm.type !== "view"
                                      ? "附件列表"
                                      : "附件预览",
                                },
                              }),
                              _vm.type !== "view"
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        padding: "14px 12px",
                                        background: "#ffffff",
                                        "text-align": "center",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-upload",
                                        {
                                          ref: "uploadRef",
                                          staticClass: "upload-demo",
                                          attrs: {
                                            action:
                                              "/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/km",
                                            "show-file-list": false,
                                            "on-success":
                                              _vm.handleAvatarSuccess,
                                            headers: _vm.headers,
                                          },
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                size: "small",
                                                type: "primary",
                                                icon: "el-icon-upload",
                                              },
                                            },
                                            [_vm._v("点击上传")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._l(_vm.treeData, function (item) {
                                return _c(
                                  "div",
                                  {
                                    key: item.id,
                                    staticClass: "img-content",
                                    on: {
                                      click: function ($event) {
                                        return _vm.getNodeClick(item)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "img-item",
                                        class: item.isActive ? "isActive" : "",
                                      },
                                      [
                                        item.extension == "jpg" ||
                                        item.extension == "png" ||
                                        item.extension == "image/jpeg"
                                          ? _c("img", {
                                              attrs: {
                                                src: item.link,
                                                alt: "",
                                              },
                                            })
                                          : _c("img", {
                                              attrs: {
                                                src: require("@/assets/images/dashboard-nodata.png"),
                                                alt: "",
                                              },
                                            }),
                                        _vm.type !== "view"
                                          ? _c("i", {
                                              staticClass:
                                                "el-icon-circle-close delete-icon",
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.handleRemove(item)
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]
                                )
                              }),
                            ],
                            2
                          ),
                          _c(
                            "el-container",
                            [
                              _c(
                                "el-main",
                                { staticStyle: { background: "#ffffff" } },
                                [
                                  _c("head-layout", {
                                    attrs: {
                                      "head-btn-options": _vm.headBtnOptions,
                                      "head-title":
                                        _vm.process.processDefinitionName,
                                    },
                                    on: {
                                      "head-flow": _vm.headFlow,
                                      "head-progress": _vm.headProgress,
                                      "handle-examine": _vm.handleExamine,
                                    },
                                  }),
                                  _c(
                                    "el-form",
                                    {
                                      ref: "form",
                                      staticStyle: { "padding-top": "12px" },
                                      attrs: {
                                        model: _vm.form,
                                        rules: _vm.rules,
                                        "label-width": "100px",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "文件名称",
                                            prop: "fileName",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              type: "text",
                                              placeholder: "请输入文件名称",
                                              disabled: _vm.type == "view",
                                            },
                                            model: {
                                              value: _vm.form.fileName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "fileName",
                                                  $$v
                                                )
                                              },
                                              expression: "form.fileName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "文件标签",
                                            prop: "fileTag",
                                          },
                                        },
                                        [
                                          _vm._l(
                                            _vm.form.fileTag,
                                            function (tag) {
                                              return _c(
                                                "el-tag",
                                                {
                                                  key: tag,
                                                  attrs: {
                                                    closable:
                                                      _vm.type !== "view",
                                                    "disable-transitions": false,
                                                  },
                                                  on: {
                                                    close: function ($event) {
                                                      return _vm.handleClose(
                                                        tag
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                        " +
                                                      _vm._s(tag) +
                                                      "\n                      "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          _vm.inputVisible
                                            ? _c("el-input", {
                                                ref: "saveTagInput",
                                                staticClass: "input-new-tag",
                                                attrs: { size: "small" },
                                                on: {
                                                  blur: _vm.handleInputConfirm,
                                                },
                                                nativeOn: {
                                                  keyup: function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter",
                                                        13,
                                                        $event.key,
                                                        "Enter"
                                                      )
                                                    )
                                                      return null
                                                    return _vm.handleInputConfirm.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: _vm.inputValue,
                                                  callback: function ($$v) {
                                                    _vm.inputValue = $$v
                                                  },
                                                  expression: "inputValue",
                                                },
                                              })
                                            : _vm._e(),
                                          _vm.type !== "view" &&
                                          !_vm.inputVisible
                                            ? _c(
                                                "el-button",
                                                {
                                                  staticClass: "button-new-tag",
                                                  attrs: { size: "small" },
                                                  on: { click: _vm.showInput },
                                                },
                                                [_vm._v("新增标签")]
                                              )
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "文件封面",
                                            prop: "fileCover",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-upload",
                                            {
                                              class: {
                                                uoloadSty: _vm.showImg,
                                                disUoloadSty: _vm.noneUploadImg,
                                              },
                                              attrs: {
                                                action:
                                                  "/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform",
                                                headers: _vm.headers,
                                                "list-type": "picture-card",
                                                "file-list": _vm.fileList,
                                                file: "file",
                                                "on-remove":
                                                  _vm.handleDeleteImgRemove,
                                                "on-success":
                                                  _vm.handleFileCoverSuccess,
                                                "on-change":
                                                  _vm.uploadImgChange,
                                                accept: ".jpeg,.jpg,.gif,.png",
                                                limit: _vm.limitCountImg,
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "file",
                                                  fn: function ({ file }) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          width: "100%",
                                                          height: "100%",
                                                        },
                                                      },
                                                      [
                                                        _c("img", {
                                                          staticClass:
                                                            "el-upload-list__item-thumbnail",
                                                          attrs: {
                                                            src: file.url,
                                                            alt: "",
                                                          },
                                                        }),
                                                        _vm.type !== "view"
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "el-upload-list__item-actions",
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "el-upload-list__item-delete",
                                                                    on: {
                                                                      click:
                                                                        _vm.handleDeleteImgRemove,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "el-icon-delete",
                                                                    }),
                                                                  ]
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    )
                                                  },
                                                },
                                              ]),
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "el-icon-plus",
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "文件分类",
                                            prop: "fileType",
                                          },
                                        },
                                        [
                                          _vm.flag
                                            ? _c("knowledge-classification", {
                                                attrs: {
                                                  selectedTagId:
                                                    _vm.form.fileTypeId,
                                                  showTagContent:
                                                    _vm.type !== "view",
                                                  view: _vm.type !== "view",
                                                },
                                                on: {
                                                  "select-tag": _vm.selectTag,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "适用范围",
                                            prop: "scopeApplication",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-radio-group",
                                            {
                                              attrs: {
                                                disabled: _vm.type == "view",
                                              },
                                              model: {
                                                value:
                                                  _vm.form.scopeApplication,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "scopeApplication",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.scopeApplication",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-radio",
                                                { attrs: { label: "1" } },
                                                [_vm._v("单位级")]
                                              ),
                                              _c(
                                                "el-radio",
                                                { attrs: { label: "2" } },
                                                [_vm._v("公司级")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.form.scopeApplication == "1"
                                        ? _c(
                                            "el-form-item",
                                            { attrs: { prop: "deptIdList" } },
                                            [
                                              _c("el-tree", {
                                                ref: "deptTree",
                                                attrs: {
                                                  data: _vm.deptTreeData,
                                                  "show-checkbox": "",
                                                  "node-key": "id",
                                                  "default-expand-all": true,
                                                  "default-checked-keys":
                                                    _vm.defaultChecked,
                                                  props: _vm.defaultProps,
                                                },
                                                on: {
                                                  "check-change":
                                                    _vm.handleCheckChange,
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "文件描述",
                                            prop: "fileDesc",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              type: "textarea",
                                              disabled: _vm.type == "view",
                                            },
                                            model: {
                                              value: _vm.form.fileDesc,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "fileDesc",
                                                  $$v
                                                )
                                              },
                                              expression: "form.fileDesc",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _vm.process.status == "todo"
                  ? _c(
                      "el-card",
                      {
                        staticStyle: { "margin-top": "20px" },
                        attrs: { shadow: "never" },
                      },
                      [
                        _c("wf-examine-form", {
                          ref: "examineForm",
                          attrs: { comment: _vm.comment, process: _vm.process },
                          on: {
                            "update:comment": function ($event) {
                              _vm.comment = $event
                            },
                            "user-select": _vm.handleUserSelect,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t(`cip.plat.wfOps.process.title.processProgressTitle`),
            visible: _vm.circulationVisible,
            width: "35%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.circulationVisible = $event
            },
          },
        },
        [_c("wf-flow", { attrs: { flow: _vm.flow } })],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t(`cip.plat.wfOps.process.title.flowChartHeadTitle`),
            visible: _vm.trackVisible,
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.trackVisible = $event
            },
          },
        },
        [
          _c("wf-design", {
            ref: "bpmn",
            staticStyle: { height: "400px" },
            attrs: { options: _vm.bpmnOption },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialog-drag", rawName: "v-dialog-drag" }],
          attrs: {
            title: "附件预览",
            modal: true,
            "modal-append-to-body": false,
            "close-on-click-modal": false,
            visible: _vm.showFileDialog,
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showFileDialog = $event
            },
          },
        },
        [
          _c("iframe", {
            ref: "fileIframe",
            staticStyle: { width: "100%", height: "500px" },
            attrs: { src: _vm.fileUrl, frameborder: "0" },
          }),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.showFileDialog = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("wf-button", {
        attrs: {
          loading: _vm.submitLoading,
          "button-list": _vm.buttonList,
          process: _vm.process,
          comment: _vm.comment,
        },
        on: {
          examine: _vm.handleExamine,
          "user-select": _vm.handleUserSelect,
          print: _vm.handlePrint,
          rollback: _vm.handleRollbackTask,
          terminate: _vm.handleTerminateProcess,
          withdraw: _vm.handleWithdrawTask,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }